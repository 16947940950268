'use client'

import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { LoadingCircle } from 'components/loading-circle/loading-circle'

type LoadingPageProps = {
	isFullScreen?: boolean
}

export const FullHeightContainer = styled.div<{ isFullScreen?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: ${props => (props.isFullScreen ? '100vh' : 'calc(100vh - 89px)')};

	${mediaBreakpointUpLg} {
		height: ${props => (props.isFullScreen ? '100vh' : 'calc(100vh - 106px)')};
	}
`

// Set default values directly in function parameters
export const LoadingPage = ({ isFullScreen = false }: LoadingPageProps) => {
	return (
		<div className="container">
			<div className="row">
				<FullHeightContainer className="col" isFullScreen={isFullScreen}>
					<LoadingCircle diameter={48} strokeWidth={4} color="twilightBlue" />
				</FullHeightContainer>
			</div>
		</div>
	)
}
